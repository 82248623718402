@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

body {
  font-family: "Inter", sans-serif;
}

.sticky {
  @apply fixed z-[9999] transition bg-white bg-opacity-80;
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sticky .navbar-logo {
  @apply py-2;
}

.sticky #navbarToggler span {
  @apply bg-dark;
}

.sticky #navbarCollapse li a {
  @apply text-dark hover:opacity-100 hover:text-primary;
}
#navbarCollapse li .ud-menu-scroll.active {
  @apply opacity-70;
}
.sticky #navbarCollapse li .ud-menu-scroll.active {
  @apply opacity-100 text-primary;
}
.sticky .loginBtn {
  @apply text-dark hover:text-primary hover:opacity-100;
}

.sticky .signUpBtn {
  @apply text-white bg-primary hover:bg-dark hover:text-white;
}

.navbarTogglerActive > span:nth-child(1) {
  @apply transform rotate-45 top-[7px];
}
.navbarTogglerActive > span:nth-child(2) {
  @apply opacity-0;
}
.navbarTogglerActive > span:nth-child(3) {
  @apply top-[-8px] rotate-[135deg];
}

